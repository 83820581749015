<template>
  <b-card :title="showStackedChart ? `Trends` : ``">
    <app-echart-stacked-area
      v-if="showStackedChart"
      class="w-100"
      :option-data="stackedOptions"
    />
    <app-echart-stacked-area
      v-if="!showOpSold"
      class="w-100"
      :option-data="totalPremOptions"
    />
    <app-echart-stacked-area
      v-if="showOpSold"
      class="w-100"
      :option-data="opSoldOptions"
    />
  </b-card>
</template>

<script>
import AppEchartStackedArea from '@core/components/charts/echart/AppEchartStackedArea.vue'
import { BCard } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    AppEchartStackedArea,
  },
  props: {
    trends: Object,
    showOpSold: Boolean,
    showStackedChart: Boolean,
  },
  data() {
    return {
      stackedOptions: {
        xAxisData: this.trends.dates,
        series: [
          {
            name: 'Total Premium',
            type: 'line',
            stack: 'Total',
            areaStyle: {},
            showSymbol: false,
            lineStyle: {
              width: 0,
            },
            color: '#3BCD10',
            data: this.trends.totalPrem ? this.trends.totalPrem : [0],
            // data: [220, 282, 191, 534, 290, 430, 350, 300, 580, 463, 380, 600, 560, 840],
          },
          {
            name: 'Opportunities Sold',
            type: 'line',
            stack: 'Total',
            areaStyle: {},
            showSymbol: false,
            lineStyle: {
              width: 0,
            },
            data: this.trends.opSold ? this.trends.opSold : [0],
            // data: [220, 282, 191, 534, 290, 430, 350, 300, 580, 463, 380, 600, 560, 840],
          },
        ],
      },
      totalPremOptions: {
        xAxisData: this.trends.dates,
        series: [
          {
            name: 'Total Premium',
            type: 'line',
            stack: 'Total',
            areaStyle: {},
            showSymbol: false,
            lineStyle: {
              width: 0,
            },
            data: this.trends.totalPrem ? this.trends.totalPrem : [0],
          },
        ],
      },
      opSoldOptions: {
        xAxisData: this.trends.dates,
        series: [
          {
            name: 'Opportunities Sold',
            type: 'line',
            stack: 'Total',
            areaStyle: {},
            showSymbol: false,
            lineStyle: {
              width: 0,
            },
            data: this.trends.opSold ? this.trends.opSold : [0],
          },
        ],
      },
    }
  },
}
</script>

<style scoped>

</style>
